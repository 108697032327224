import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Box } from '../components/Layout';
import colors from '../utils/colors';
import PageWrapper from '../components/PageWrapper';
import PostFeedList from '../components/CMS/postFeedList';
import { MetaData } from '../components/common/meta';
import SubNav from '../components/Navigation/subNav';

const AllPostList = ({ data, location, pageContext }) => {
    const posts = data.allGhostPost.edges.map((post) => {
        //console.log('***+*+* post:' + post.node.slug);
        return post.node;
    })

    return (
        <>
            <MetaData location={location} />

            <PageWrapper>
                <>
                <Box
                    width={[1, 1, 2 / 3]}
                    minWidth={[0,0,'1024px']}
                    m={['4rem 0 1.5rem 0', '4rem 0 1.5rem 0', '4rem auto 1.5rem auto']}>    
                    <SubNav/>
                    <Box
                    mt={['1.5rem', '1.5rem', '1.5rem']} mb={['1rem', '1rem', '1rem']}
                    color={colors.secondary}
                    >
                        <h1>All News</h1>
                        
                    </Box>
                    <PostFeedList hideImage={true} posts={posts} pageContext={pageContext}/>
                </Box>
                </>
            </PageWrapper>
        </>
    )
}

AllPostList.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,    
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default AllPostList

export const pageQuery = graphql`
  query AllPostQuery($posts: [String]!) {
    allGhostPost(filter: {id: {in: $posts}}, sort: {order: [DESC], fields: [published_at]}) {
        totalCount
        edges {
          node {
            excerpt
            feature_image
            featured
            title
            slug
            published_at
            reading_time
            primary_tag {
              name
              slug
            }
            tags {
              slug
              name
              visibility
              feature_image
            }
          }
        }
      }
  }
`
